var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vlt-payment-container"},[_c('div',{staticClass:"payment-details"},[(
        _vm.paymentData.paymentdata.frompage !== 'subscribe' &&
        _vm.paymentData.paymentdata.frompage !== 'plans'
      )?_c('div',{staticClass:"payment-img"},[(_vm.picture)?_c('img',{staticClass:"payment-img-responsive",attrs:{"src":_vm.picture,"alt":"vlt-prof"}}):_c('img',{staticClass:"payment-img-responsive",attrs:{"src":_vm.defaultPicture,"alt":"vlt-prof","loading":"lazy"}})]):_vm._e(),(
        _vm.paymentData.paymentdata.frompage !== 'subscribe' &&
        _vm.paymentData.paymentdata.frompage !== 'plans'
      )?_c('div',{staticClass:"payment-details-sub"},[_c('p',{staticClass:"title"},[_c('b',[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"quality"},[_c('span',[_vm._v("Quality")]),_c('span',{staticClass:"quality-btn"},[_vm._v(_vm._s(_vm.paymentData.paymentdata.selectedtype))])])]):_vm._e(),(
        _vm.paymentData.paymentdata.frompage === 'subscribe' ||
        _vm.paymentData.paymentdata.frompage === 'plans'
      )?_c('div',{staticClass:"main-card"},[_c('h4',[_vm._v(_vm._s(_vm.currencySymbol)+_vm._s(_vm.amount))]),_c('p',{staticClass:"content-validity"},[_vm._v(_vm._s(_vm.contentValidity))]),_c('p',{staticClass:"content-type"},[_vm._v(_vm._s(_vm.planType))])]):_vm._e(),_c('div',{staticClass:"payment-details-sub-1"},[_c('div',[_c('span',[_vm._v("Amount")]),_c('span',{staticClass:"amount"},[_vm._v(_vm._s(_vm.currencySymbol)+" "+_vm._s(_vm.amount))])]),(_vm.paymentData.paymentdata.subscriptiontype === 'UPGRADE')?_c('div',[_c('span',[_vm._v("Balance in last plan")]),_c('span',{staticClass:"amount"},[_vm._v(_vm._s(_vm.currencySymbol)+" "+_vm._s(_vm.lastPlanAmount))])]):_vm._e(),_c('div',[_c('span',[_vm._v("Discount")]),_c('span',{staticClass:"amount"},[_vm._v(_vm._s(_vm.currencySymbol)+_vm._s(_vm.discountedAmount))])]),_c('hr'),_c('div',{staticClass:"total"},[_c('span',[_vm._v("Total")]),_c('span',{staticClass:"amount"},[_vm._v(_vm._s(_vm.currencySymbol)+" "+_vm._s(_vm.totalAmount))])])])]),_c('div',{staticClass:"payment-setting"},[_c('div',{staticClass:"card-tab"},_vm._l((_vm.cardTabs),function(tab,i){return _c('button',{key:i,staticClass:"tab-link",class:tab === _vm.activeTabName ? 'activetabname' : '',on:{"click":function($event){return _vm.renderTab(tab)}}},[_c('span',[_vm._v(_vm._s(tab))])])}),0),_c('div',{staticClass:"tab-details"},[_c(_vm.renderComponent,{tag:"component",attrs:{"totalAmount":_vm.totalAmountToGateway,"paymentData":_vm.paymentData}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }