<template>
  <div class="vlt-payment-container">
    <div class="payment-details">
      <div
        class="payment-img"
        v-if="
          paymentData.paymentdata.frompage !== 'subscribe' &&
          paymentData.paymentdata.frompage !== 'plans'
        "
      >
        <img
          v-if="picture"
          :src="picture"
          alt="vlt-prof"
          class="payment-img-responsive"
        />
        <img
          v-else
          :src="defaultPicture"
          alt="vlt-prof"
          class="payment-img-responsive"
          loading="lazy"
        />
      </div>
      <div
        class="payment-details-sub"
        v-if="
          paymentData.paymentdata.frompage !== 'subscribe' &&
          paymentData.paymentdata.frompage !== 'plans'
        "
      >
        <p class="title">
          <b>{{ title }}</b>
        </p>
        <div class="quality">
          <span>Quality</span>
          <span class="quality-btn">{{
            paymentData.paymentdata.selectedtype
          }}</span>
        </div>
      </div>
      <div
        class="main-card"
        v-if="
          paymentData.paymentdata.frompage === 'subscribe' ||
          paymentData.paymentdata.frompage === 'plans'
        "
      >
        <h4>{{ currencySymbol }}{{ amount }}</h4>
        <p class="content-validity">{{ contentValidity }}</p>
        <p class="content-type">{{ planType }}</p>
        <!-- <div class="vlt-btn submit-btns">
        <button class="btn-login" @click="actClicked">{{ data }}</button>
        </div>-->
      </div>
      <div class="payment-details-sub-1">
        <div>
          <span>Amount</span>
          <span class="amount">{{ currencySymbol }} {{ amount }}</span>
        </div>
        <div v-if="paymentData.paymentdata.subscriptiontype === 'UPGRADE'">
          <span>Balance in last plan</span>
          <span class="amount">{{ currencySymbol }} {{ lastPlanAmount }}</span>
        </div>
        <div>
          <span>Discount</span>
          <span class="amount">{{ currencySymbol }}{{ discountedAmount }}</span>
        </div>
        <hr />
        <div class="total">
          <span>Total</span>
          <span class="amount">{{ currencySymbol }} {{ totalAmount }}</span>
        </div>
      </div>
    </div>
    <div class="payment-setting">
      <div class="card-tab">
        <button
          class="tab-link"
          v-for="(tab, i) in cardTabs"
          :key="i"
          :class="tab === activeTabName ? 'activetabname' : ''"
          @click="renderTab(tab)"
        >
          <span>{{ tab }}</span>
        </button>
      </div>
      <div class="tab-details">
        <component
          :is="renderComponent"
          :totalAmount="totalAmountToGateway"
          :paymentData="paymentData"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/main";
import appMixins from "@/mixins/appMixins";

export default {
  props: ["paymentData"],
  data() {
    return {
      defaultPicture: require("@/assets/icons/placeholder-landscape.png"),
      activeTabName: "",
      title: "",
      subscriberid: "",
      amount: null,
      contentValidity: null,
      planType: null,
      totalAmount: null,
      discountedAmount: null,
      currencySymbol: null,
      picture: null,
      lastPlanAmount: null,
      totalAmountToGateway: null,
      flagAutologin: true,
      cardTabs: null,
    };
  },
  methods: {
    ...mapActions(["actGetSubscriber", "actGetTicket"]),
    actSetCardTab() {
      // this.cardTabs = ["Credit/Debit Card", "Net Banking", "Wallet"];
      this.cardTabs = ["Payment Gateway"];

      // this.activeTabName = "Credit/Debit Card";
      this.activeTabName = "Payment Gateway";

      this.renderComponent = "Wallet";
    },
    renderTab(tab) {
      // console.log("tab ", tab);

      if (tab === "Payment Gateway") {
        this.renderComponent = "Wallet";
      }
      // if (tab === "Credit/Debit Card") {
      //   this.renderComponent = "Card";
      // } else if (tab === "Net Banking") {
      //   this.renderComponent = "Banking";
      // } else if (tab === "Wallet") {
      //   this.renderComponent = "Wallet";
      // }
      this.activeTabName = tab;
    },
    getpaymentDetails() {
      let localData = JSON.parse(localStorage.getItem("loginType"));
      let param = {};
      param.email = localData.email;

      this.actGetSubscriber(param)
        .then((response) => {
          this.subscriberid = response.subscriberid;
          this.editData = response;
          this.name = response.subscribername;
          this.email = response.email;
          if (response.gender) {
            this.gender = response.gender;
          } else {
            this.gender = "---";
          }

          if (response.dob) {
            let tempDob = response.dob.split("-");
            this.dob = tempDob[2] + "-" + tempDob[1] + "-" + tempDob[0];
          } else {
            this.dob = "---";
          }

          if (response.picture) {
            console.log("response ", response);
            this.picture = response.picture;
          }

          this.planStatus = response.planstatus;
        })
        .catch((error) => {
          console.log("error ", error);
          if (error.status === 401) {
            // this.unauthorisedHandler();
            if (this.flagAutologin) {
              this.commonAutoLogin(this.getpaymentDetails);
              this.flagAutologin = false;
              return;
            }
          }
        });
    },
    setValues() {
      // console.log("paymentdata ", this.paymentData);
      this.contentValidity = this.paymentData.paymentdata.selectedtype;
      this.planType = this.paymentData.paymentdata.content.plantype;
      this.title = this.paymentData.paymentdata.content.title;

      if (
        this.paymentData.paymentdata.content.posters &&
        this.paymentData.paymentdata.content.posters.length
      ) {
        let landscapePosters = this.paymentData.paymentdata.content.posters.filter(
          (pos) => pos.orientation === "LANDSCAPE"
        );
        if (landscapePosters.length) {
          let posterIndex = landscapePosters.findIndex(
            (pos) => pos.quality === "SD"
          );
          if (posterIndex > -1) {
            this.picture = landscapePosters[posterIndex].url;
          }
        }
      }
      if (
        this.paymentData.paymentdata.frompage === "subscribe" ||
        this.paymentData.paymentdata.frompage === "plans"
      ) {
        if (
          this.paymentData.paymentdata.selectedtype === "MONTHLY" ||
          this.paymentData.paymentdata.selectedtype === "Monthly"
        ) {
          this.amount = this.paymentData.paymentdata.content.monthlyprice / 100;
          this.totalAmount =
            this.paymentData.paymentdata.content.monthlydiscountedprice / 100;
        } else {
          this.amount = this.paymentData.paymentdata.content.yearlyprice / 100;
          this.totalAmount =
            this.paymentData.paymentdata.content.yearlydiscountedprice / 100;
        }
        this.discountedAmount = this.amount - this.totalAmount;
        this.totalAmountToGateway = this.totalAmount;
        if (this.paymentData.paymentdata.content.currency === "INR") {
          this.currencySymbol = "₹ ";
        } else {
          this.currencySymbol = "$ ";
        }

        if (this.paymentData.paymentdata.subscriptiontype === "UPGRADE") {
          this.lastPlanAmount =
            this.paymentData.paymentdata.planSubscribed.proratedbalanceamount /
            100;

          this.totalAmount = this.totalAmount - this.lastPlanAmount;
        }
      } else if (this.paymentData.paymentdata.frompage === "purchase") {
        if (this.paymentData.paymentdata.content) {
          let localCountry = localStorage.getItem("country");
          let countryCode;
          if (localCountry === "IN") {
            countryCode = "INR";
          } else {
            countryCode = "USD";
          }
          this.paymentData.paymentdata.content.price.forEach((element) => {
            if (
              element.quality === this.paymentData.paymentdata.selectedtype &&
              element.currency === countryCode
            ) {
              if (element.currency === "INR") {
                this.currencySymbol = "₹ ";
              } else {
                this.currencySymbol = "$ ";
              }

              this.amount = element.amount / 100;
              this.discountedAmount = 0;
              this.totalAmount = this.amount - this.discountedAmount;
              this.totalAmountToGateway = this.totalAmount;
            }
          });
        }
      }
    },
  },
  mounted() {},
  created() {
    if (
      this.paymentData &&
      this.paymentData.paymentdata &&
      this.paymentData.paymentdata.content
    ) {
      this.picture = this.paymentData.paymentdata.content.poster;
    }
    this.actSetCardTab();
    this.setValues();
  },
  components: {
    // Card: () =>
    //   import(
    //     /* webpackChunkName: "card" */ "@/components/Popups/payment/paymentCard.vue"
    //   ),
    // Banking: () =>
    //   import(
    //     /* webpackChunkName: "banking" */ "@/components/Popups/payment/paymentBanking.vue"
    //   ),
    Wallet: () =>
      import(
        /* webpackChunkName: "wallet" */ "@/components/Popups/payment/paymentWallet.vue"
      ),
  },
  mixins: [appMixins],
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";

.vlt-payment-container {
  padding: 3% 0%;
  display: flex;
  font-family: $font-family;
  font-weight: $font-weight-medium;
  color: $font-clr-white-1;
  height: 440px;
  margin-top: 3%;
  @include breakpoint(max768) {
    padding: 3% 10%;
    height: auto;
    flex-direction: column;
  }
  .payment-details {
    width: 30%;
    margin-right: 2%;
    // height: 100%;
    // background: $clr-bg-gray-light-1;
    border-radius: 5px;
    .main-card {
      width: 100%;
      background: $clr-color-black-2 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px $clr-color-black-3;
      border: 3px solid $clr-bg-gray-dark;
      border-radius: 12px;
      padding: 10% 3% 8%;
      display: flex;
      align-items: center;
      flex-direction: column;
      h4 {
        color: $font-clr-white;
      }
      .content-type {
        color: $font-clr-white;
        font-family: $font-family;
        font-weight: $font-weight-semibold;
      }
      .content-validity {
        color: $font-clr-gray;
        font-family: $font-family;
        font-weight: $font-weight-medium;
      }
    }
    @include breakpoint(max768) {
      width: 100%;
    }
    .payment-img {
      // padding: 10% 31%;
      // text-align: center;
      // padding-bottom: 3%;

      .payment-img-responsive {
        width: 208px;
        height: 117px;
        border-radius: 5px;
        vertical-align: middle;
        // border: solid 1px $theme-primary;
      }
    }
    .amount {
      float: right;
    }
    .payment-details-sub {
      text-align: center;

      .title {
        font-size: 1rem;
        color: $font-clr-white;
        font-family: $font-family;
        font-weight: $font-weight-semibold;
        letter-spacing: 0px;
        text-align: left;
        margin-top: 5%;
      }
      .quality {
        margin-top: 10%;
        text-align: left;
        font-size: 1rem;

        .quality-btn {
          background-color: $theme-primary;
          color: $clr-white;
          padding: 2px 6px;
          border-radius: 3px;
          font-size: 0.85rem;
          margin-left: 5px;
        }
      }
    }

    .payment-details-sub-1 {
      // padding: 7%;
      // margin-top: 30%;
      margin-top: 15%;
      .total {
        color: $font-clr-gray;
        font-family: $font-family;
        font-weight: $font-weight-semibold;
        font-size: 1rem;
      }
      hr {
        display: block;
        height: 1px;
        border: 0;
        margin: 5% 2%;
        border: 1px solid $clr-white;
        opacity: 0.1;
        padding: 0;
      }
      span {
        font-size: 0.9rem;
      }

      // /* Base for label styling */
      // [type="checkbox"]:not(:checked),
      // [type="checkbox"]:checked {
      //   position: absolute;
      //   left: -9999px;
      // }
      // [type="checkbox"]:not(:checked) + label,
      // [type="checkbox"]:checked + label {
      //   position: relative;
      //   padding-left: 1.95em;
      //   cursor: pointer;
      // }

      // /* checkbox aspect */
      // [type="checkbox"]:not(:checked) + label:before,
      // [type="checkbox"]:checked + label:before {
      //   content: "";
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   width: 1.25em;
      //   height: 1.25em;
      //   background: $clr-bg-gray-light-1;
      //   border-radius: 4px;
      //   box-shadow: inset 0 1px 3px $clr-dark-hlt-8;
      // }
      // /* checked mark aspect */
      // [type="checkbox"]:not(:checked) + label:after,
      // [type="checkbox"]:checked + label:after {
      //   content: "\2713\0020";
      //   position: absolute;
      //   top: 0em;
      //   left: 0em;
      //   font-size: 1.3em;
      //   border-radius: 4px;
      //   line-height: 0.8;
      //   color: $clr-bg-gray-light-1;
      //   background: $theme-primary;
      //   border: 2px solid $theme-primary;
      //   transition: all 0.2s;
      //   font-family: "Lucida Sans Unicode", "Arial Unicode MS", Arial;
      // }
      // /* checked mark aspect changes */
      // [type="checkbox"]:not(:checked) + label:after {
      //   opacity: 0;
      //   transform: scale(0);
      // }
      // [type="checkbox"]:checked + label:after {
      //   opacity: 1;
      //   transform: scale(1);
      // }
      // /* disabled checkbox */
      // [type="checkbox"]:disabled:not(:checked) + label:before,
      // [type="checkbox"]:disabled:checked + label:before {
      //   box-shadow: none;
      //   border-color: #bbb;
      //   background-color: #ddd;
      // }
      // [type="checkbox"]:disabled:checked + label:after {
      //   color: #999;
      // }
      // [type="checkbox"]:disabled + label {
      //   color: #aaa;
      // }

      @include breakpoint(max768) {
        display: flex;
        flex-direction: column;
        padding: 7% 5%;
        .gender-dob {
          width: 50%;
        }
      }
      .title {
        margin-top: 5%;
      }
      .details {
        color: $font-clr-white;
        margin-top: 1%;
      }
    }

    .payment-details-sub-2 {
      display: flex;
      // padding: 7%;
      margin-top: 30%;
      .remove {
        font-family: $font-family;
        font-weight: $font-weight-bold;
        color: $theme-primary;
      }
      p {
        font-size: 0.8rem;
      }

      @include breakpoint(max768) {
        padding: 3% 25%;
      }
      .edit-change {
        width: 50%;
        cursor: pointer;
      }
      .active-edit-change {
        color: $theme-primary;
      }
    }
    .coupon-details {
      background: $clr-bg-gray-light-1;
      border: 1px solid $clr-bg-gray-light-4;
    }
  }

  .payment-setting {
    width: 70%;
    position: relative;
    // height: 100%;
    background: $clr-bg-gray-light-1;
    border-radius: 5px;
    margin-left: 2%;
    overflow-y: auto;
    @include breakpoint(max768) {
      width: 100%;
      margin-left: 0%;
      margin-top: 2%;
    }
    .card-tab {
      right: 0;
      width: 100%;
      position: relative;
      border-bottom: solid 2px $clr-bg-gray-light-2;
      // @include breakpoint(max768) {
      //   text-align: center;
      // }
      @include breakpoint(max480) {
        height: 60px;
        .tab-link {
          padding: 2px 0 !important;
        }
        button:first-child {
          margin-left: 2vw !important;
        }
      }
      button:first-child {
        margin-left: 8%;
      }

      .tab-link {
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        text-transform: capitalize;
        font-family: $font-family;
        font-weight: $font-weight-semibold;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 0.37px;
        line-height: 25px;
        padding: 15px 0;
        padding-right: 3%;
        color: $font-clr-white-1;

        span {
          padding: 5px 15px;
          font-size: 0.8rem !important;
        }
      }
      .activetabname {
        span {
          background: $clr-bg-gray-dark 0% 0% no-repeat padding-box;
          border-radius: 6px;
          opacity: 1;
          color: $font-clr-white;
          border-bottom: 0px;
        }
      }
    }

    .tab-details {
      background: $clr-bg-gray-light-1;
      padding: 3%;
      @include breakpoint(max768) {
        margin-bottom: 1%;
      }
    }
  }
}
</style>